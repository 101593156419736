import { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { Slide } from "react-awesome-reveal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { ContactProps, ValidationTypeProps } from "./types";
import { Button } from "../../common/Button";
import Block from "../Block";
import Input from "../../common/Input";
import TextArea from "../../common/TextArea";
import { ContactContainer, FormGroup, Span, ButtonContainer } from "./styles";

interface FormValues {
  name: string;
  email: string;
  message: string;
}

const Contact = ({ title, content, id, t }: ContactProps) => {
  const [submissionSuccess, setSubmissionSuccess] = useState<boolean | null>(
    null
  );
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);

  useEffect(() => {
    // Check local storage to see if the form was previously submitted
    const formSubmitted = localStorage.getItem("contactFormSubmitted");
    if (formSubmitted) {
      setHasSubmitted(true); // Show success message if already submitted
    }
  }, []);

  const validationSchema = Yup.object({
    name: Yup.string().required(t("Name is required")),
    email: Yup.string()
      .email(t("Invalid email"))
      .required(t("Email is required")),
    message: Yup.string().required(t("Message is required")),
  });

  const formik = useFormik<FormValues>({
    initialValues: { name: "", email: "", message: "" },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        await addDoc(collection(db, "contacts"), {
          name: values.name,
          email: values.email,
          message: values.message,
          timestamp: new Date(),
        });
        setSubmissionSuccess(true);
        setHasSubmitted(true); // Hide form after successful submission
        localStorage.setItem("contactFormSubmitted", "true"); // Save submission status to local storage
        resetForm();
      } catch (error) {
        console.error("Error submitting form data:", error);
        setSubmissionSuccess(false);
      }
    },
  });

  const ValidationType = ({ type }: ValidationTypeProps) => {
    const errorMessage = formik.errors[type as keyof typeof formik.errors];
    return <Span>{errorMessage}</Span>;
  };

  return (
    <ContactContainer id={id}>
      <Row justify="space-between" align="middle">
        <Col lg={12} md={11} sm={24} xs={24}>
          {title && content && (
            <Slide direction="left" triggerOnce>
              <Block title={title} content={content} />
            </Slide>
          )}
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Slide direction="right" triggerOnce>
            <>
              {hasSubmitted ? (
                <p style={{ color: "green", marginTop: "20px" }}>
                  {t("contactForm.submitSuccess")}
                </p>
              ) : (
                <FormGroup autoComplete="off" onSubmit={formik.handleSubmit}>
                  <Col span={24}>
                    <Input
                      type="text"
                      name="name"
                      placeholder={t("Your Name")}
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.name && <ValidationType type="name" />}
                  </Col>
                  <Col span={24}>
                    <Input
                      type="text"
                      name="email"
                      placeholder={t("Your Email")}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.email && <ValidationType type="email" />}
                  </Col>
                  <Col span={24}>
                    <TextArea
                      placeholder={t("Your Message")}
                      name="message"
                      value={formik.values.message}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.message && (
                      <ValidationType type="message" />
                    )}
                  </Col>
                  <ButtonContainer>
                    <Button type="submit">{t("Submit")}</Button>
                  </ButtonContainer>
                </FormGroup>
              )}
              {submissionSuccess === false && (
                <p style={{ color: "red", marginTop: "20px" }}>
                  {t("contactForm.submitError")}
                </p>
              )}
            </>
          </Slide>
        </Col>
      </Row>
    </ContactContainer>
  );
};

export default withTranslation()(Contact);
