import Home from "../pages/Home";
import PrivacyPolicy from "../pages/Privacy Policy";
// import TermsOfService from "../pages/Terms Of Service";

const routes = [
  { path: "/", component: Home, exact: true },
  { path: "/Home", component: Home, exact: true },
  { path: "/Privacy Policy", component: PrivacyPolicy, exact: true },
  // { path: "/Terms Of Service", component: TermsOfService, exact: true },
];

export default routes;
