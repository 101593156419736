// src/components/Partners/index.tsx

import { PartnerContainer, LogoImage, LogoRow } from "./styles";

interface Logo {
  src: string;
  alt: string;
}

interface PartnersProps {
  logos: Logo[];
}

const Partners: React.FC<PartnersProps> = ({ logos }) => {
  return (
    <PartnerContainer>
      <LogoRow>
        {logos.map((logo, index) => (
          <LogoImage key={index} src={logo.src} alt={logo.alt} />
        ))}
      </LogoRow>
    </PartnerContainer>
  );
};

export default Partners;
