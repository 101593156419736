// src/components/Partners/styles.ts

import styled from "styled-components";

export const PartnerContainer = styled.div`
  padding: 3rem 0;
  text-align: center;

  h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
`;

export const LogoRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
  width: 100%;
  padding: 1rem 0;
  overflow-x: auto; /* Enable horizontal scrolling for smaller screens */

  @media (max-width: 768px) {
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
    justify-content: space-around; /* Center-align in two rows */
    gap: 1rem;
  }
`;

export const LogoImage = styled.img`
  max-width: 100px;
  margin: 0.5rem;
  filter: grayscale(100%);
  opacity: 0.8;
  object-fit: contain;
  transition: filter 0.3s ease, opacity 0.3s ease;

  &:hover {
    filter: grayscale(0%);
    opacity: 1;
  }
`;
