import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import "antd/dist/antd.css";

import Router from "./router";
import i18n from "./translation";
import { Styles } from "./styles/styles"; // Update with the correct path to Styles

const App = () => {
  // Determine the direction based on the current language
  const direction = i18n.language === "he" ? "rtl" : "ltr";

  // Set the dir attribute on the HTML document
  document.documentElement.setAttribute("dir", direction);

  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <Styles dir={direction} />
        <Router />
      </I18nextProvider>
    </BrowserRouter>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
