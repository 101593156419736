import { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Styles } from "../styles/styles";
import Header from "../components/Header";
import routes from "./config";
import Footer from "../components/Footer";

const Router = () => {
  const { i18n } = useTranslation(); // Use i18n to get the current language

  // Determine the direction based on the language
  const direction = i18n.language === "he" ? "rtl" : "ltr";

  return (
    <Suspense fallback={null}>
      {/* Pass the dir prop to Styles based on language */}
      <Styles dir={direction} />
      <Header />
      <Switch>
        {routes.map((routeItem) => (
          <Route
            key={routeItem.path}
            path={routeItem.path}
            component={routeItem.component}
            exact={routeItem.exact}
          />
        ))}
      </Switch>
      <Footer />
    </Suspense>
  );
};

export default Router;
