import { useTranslation } from "react-i18next";
import Contact from "../../components/ContactForm";
import MiddleBlock from "../../components/MiddleBlock";
import Container from "../../common/Container";
import ScrollToTop from "../../common/ScrollToTop";
import ContentBlock from "../../components/ContentBlock";
import Partners from "../../components/Partners";
import styled from "styled-components";
import { FaApple, FaGooglePlay } from "react-icons/fa"; // Importing icons from react-icons

const logos = [
  { src: "/img/png/whisky.png", alt: "Whiskey Bar" },
  { src: "/img/png/vin.png", alt: "Vini" },
  { src: "/img/png/dna.png", alt: "DNA" },
  { src: "/img/png/kululu.png", alt: "Kululu" },
  { src: "/img/png/oscar.jpg", alt: "Oscar Wilde" },
  { src: "/img/png/butchers.png", alt: "butchers" },
  { src: "/img/png/lager.png", alt: "lager" },
  { src: "/img/png/passador.png", alt: "passador" },
  { src: "/img/png/sako.png", alt: "sako" },
  { src: "/img/png/tastingRoom.png", alt: "tasting room" },
];

// Styled component for store links
const StoreLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 2rem 0;

  a {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.5rem;
    font-size: 1.2rem;
    color: #fff;
    background-color: #000;
    border-radius: 8px;
    text-decoration: none;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #555;
    }
  }

  .apple-store {
    background-color: #000;
  }

  .google-play {
    background-color: #4285f4;
  }
`;

const Home = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <ScrollToTop />

      <ContentBlock
        direction="right"
        title="IntroContent.title"
        content="IntroContent.text"
        // button={[{ title: "IntroContent.button" }]}
        icon="developer.svg"
        id="intro"
      />

      <StoreLinks>
        <a
          href="/"
          target="_blank"
          rel="noopener noreferrer"
          className="apple-store"
        >
          <FaApple style={{ marginRight: "8px", marginLeft: "8px" }} />
          {t("Apple Store")}
        </a>
        <a
          href="/"
          target="_blank"
          rel="noopener noreferrer"
          className="google-play"
        >
          <FaGooglePlay style={{ marginRight: "8px", marginLeft: "8px" }} />
          {t("Google Play")}
        </a>
      </StoreLinks>

      <Partners logos={logos} />

      <MiddleBlock
        title="MiddleBlockContent.title"
        content="MiddleBlockContent.text"
        button="MiddleBlockContent.button"
      />

      <ContentBlock
        direction="left"
        title="AboutContent.title"
        content="AboutContent.text"
        section={t("AboutContent.section", { returnObjects: true })}
        icon="graphs.svg"
        id="about"
      />

      <ContentBlock
        direction="right"
        title="MissionContent.title"
        content="MissionContent.text"
        icon="product-launch.svg"
        id="mission"
      />

      <ContentBlock
        direction="left"
        title="ProductContent.title"
        content="ProductContent.text"
        section={t("ProductContent.section", { returnObjects: true })}
        icon="waving.svg"
        id="product"
      />

      <Contact
        title="ContactContent.title"
        content="ContactContent.text"
        id="contact"
      />
    </Container>
  );
};

export default Home;
