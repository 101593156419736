import Container from "../../common/Container";
import ScrollToTop from "../../common/ScrollToTop";
import styled from "styled-components";

// Styled component to enforce LTR and style headings
const PrivacyContent = styled.div`
  direction: ltr;

  h1 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }

  h3 {
    font-size: 1.4rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  h4 {
    font-size: 1.2rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  p,
  ul,
  li {
    font-size: 1rem;
    line-height: 1.6;
  }

  ul {
    padding-left: 1.5rem;
  }

  strong {
    font-weight: bold;
  }
`;

const PrivacyPolicy = () => {
  return (
    <Container>
      <div dir="ltr" style={{ textAlign: "left" }}>
        <ScrollToTop />
        <PrivacyContent dir="ltr">
          <h1>Privacy Policy</h1>
          <p>
            Welcome to Vardo.ai. We are committed to protecting your privacy and
            ensuring transparency in how we collect, use, and store your
            information. This Privacy Policy outlines how we use Google
            Analytics 4 (GA4), manage data in browser storage, and the measures
            we take to protect your privacy.
          </p>

          <h3>1. Information We Collect</h3>

          <h4>1.1 Information You Provide</h4>
          <p>
            <strong>Form Submissions:</strong> When you fill out forms on our
            website, we collect the information you submit, including, but not
            limited to, your name, email address, and any other details you
            voluntarily provide.
          </p>

          <h4>1.2 Automatically Collected Information</h4>
          <p>
            <strong>Google Analytics 4 (GA4):</strong> We use GA4 to collect
            information about how visitors interact with our website. This may
            include pages visited, time spent on each page, referral URLs,
            device type, operating system, and more. GA4 provides aggregate data
            to help us improve our website’s functionality and user experience.
          </p>
          <p>
            <strong>Browser Storage:</strong> We use browser storage (e.g.,
            cookies, local storage) to remember certain information about your
            interactions with our site, such as whether you have previously
            submitted a form. This helps us streamline your experience and avoid
            redundant submissions.
          </p>

          <h3>2. How We Use Your Information</h3>
          <p>We use the information we collect for the following purposes:</p>
          <ul>
            <li>
              <strong>Improvement of Website:</strong> Data collected through
              GA4 helps us understand how users engage with our website and
              allows us to enhance the overall user experience.
            </li>
            <li>
              <strong>Form Management:</strong> Browser storage is used to
              remember form submissions. This prevents duplicate form
              submissions and improves your user experience.
            </li>
          </ul>

          <h3>3. How We Share Your Information</h3>
          <p>
            We do not share your personal information with third parties, except
            in the following cases:
          </p>
          <ul>
            <li>
              <strong>Service Providers:</strong> We may share data with
              third-party service providers that help us operate our website,
              conduct analytics, or provide other business-related services.
              These providers are required to protect your information and use
              it only for the purposes we specify.
            </li>
            <li>
              <strong>Legal Requirements:</strong> We may disclose information
              if required by law, in response to legal proceedings, or to
              protect our rights, property, or safety.
            </li>
          </ul>

          <h3>4. Cookies and Browser Storage</h3>
          <p>
            <strong>Cookies</strong> and <strong>local storage</strong> are
            small data files that are placed on your device. We use these tools
            to enhance your experience on our website:
          </p>
          <ul>
            <li>
              <strong>Analytics Cookies:</strong> These cookies collect data on
              website usage through GA4, such as pages visited and time spent,
              to help us understand how users interact with Vardo.ai.
            </li>
            <li>
              <strong>Functional Cookies:</strong> Browser storage is used to
              remember if you have already submitted a form, allowing us to
              provide a more efficient experience.
            </li>
          </ul>
          <p>
            <strong>You may disable cookies</strong> and browser storage through
            your browser settings. However, doing so may limit your ability to
            use certain features on our site.
          </p>

          <h3>5. Data Security</h3>
          <p>
            We take data security seriously. We implement various measures to
            protect your data from unauthorized access or disclosure. However,
            please note that no method of transmission over the internet or
            electronic storage is completely secure.
          </p>

          <h3>6. Your Rights</h3>
          <p>
            Depending on your location, you may have rights regarding your
            personal information, such as:
          </p>
          <ul>
            <li>
              <strong>Access:</strong> Request access to the personal
              information we hold about you.
            </li>
            <li>
              <strong>Correction:</strong> Request correction of any inaccurate
              or incomplete data.
            </li>
            <li>
              <strong>Deletion:</strong> Request deletion of your personal
              information, subject to legal obligations.
            </li>
            <li>
              <strong>Objection to Processing:</strong> Object to our processing
              of your personal information in certain circumstances.
            </li>
          </ul>

          <h3>7. Third-Party Links</h3>
          <p>
            Our website may contain links to third-party websites. This Privacy
            Policy does not apply to third-party sites. We recommend reviewing
            the privacy policies of any websites you visit through our links.
          </p>

          <h3>8. Changes to This Privacy Policy</h3>
          <p>
            We may update this Privacy Policy from time to time. Any changes
            will be posted on this page with an updated “Last Updated” date.
          </p>

          <h3>9. Contact Us</h3>
          <p>
            If you have any questions or concerns regarding this Privacy Policy
            or our practices, please contact us using the contact form on the
            main page
          </p>
        </PrivacyContent>
      </div>
    </Container>
  );
};

export default PrivacyPolicy;
